import { FC } from 'react';

import { CartButton } from '@components/navigation/buttons/CartButton';
import { useUI } from '@components/ui/context';

import { DesktopNavigation } from './desktop/DesktopNavigation';
import { MobileNavigation } from './mobile/MobileNavigation';
import { MobileDropDownTransitiveProps, NavigationProps } from './types';

type Props = Partial<NavigationProps> & MobileDropDownTransitiveProps;

export const Navigation: FC<Props> = ({ linksOrMenus, calcDropDownTop }) => {
  const { globalParams } = useUI();
  const inApp = globalParams.inapp === 1;

  if (!linksOrMenus) {
    return null;
  }

  const filteredLinksOrMenus = linksOrMenus.filter((item) => item.contentType);

  if (inApp) {
    return (
      <header className="container bg-white flex flex-row justify-end p-4 lg:px-6">
        <CartButton />
      </header>
    );
  }

  return (
    <>
      <MobileNavigation linksOrMenus={filteredLinksOrMenus} calcDropDownTop={calcDropDownTop} />
      <DesktopNavigation linksOrMenus={filteredLinksOrMenus} />
    </>
  );
};
