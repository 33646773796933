import { CF_SITE_LOCALE, CF_USER_COUNTRY } from '@constants/cloudflare';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { useEffect, useMemo, useState } from 'react';

import Button from '@components/ui/Button/Button';
import Text from '@components/ui/Text/Text';
import { trackMissedBrowserLocale } from '@lib/gtag';
import { isLife360IntlSite } from '@lib/storefront';

import { browserLanguageMapping, DISMISS_LANGUAGE_SWITCHER, regionCountries } from './constants';

import s from './LanguageSwitcher.module.scss';

export const LanguageSwitcher = () => {
  const { t } = useTranslation(['common', 'locale']);
  const router = useRouter();
  const { locale = i18n.defaultLocale } = router;
  const [showPopup, setShowPopup] = useState(false);
  const [showAdditionalLanguages, setShowAdditionalLanguages] = useState(false);
  const userCountry = useMemo(() => Cookies.get(CF_USER_COUNTRY)?.toLowerCase() || '', []);

  const hasBeenDismissed = Cookies.get(`${DISMISS_LANGUAGE_SWITCHER}`);

  const onClose = () => {
    setShowPopup(false);
    Cookies.set(`${DISMISS_LANGUAGE_SWITCHER}`, 'yes', { expires: 1 });
  };

  const redirectToLocale = (storeLocale: string) => {
    Cookies.set(CF_SITE_LOCALE, storeLocale, { expires: 1 });
    router.push('/', '', { locale: storeLocale });
  };

  const onSubmit = () => {
    if (regionCountries[browserLanguageMapping[userCountry]].additionalLanguages.length) {
      setShowAdditionalLanguages(true);
    } else {
      setShowAdditionalLanguages(false);
      setShowPopup(false);
      Cookies.set(`${DISMISS_LANGUAGE_SWITCHER}`, 'yes', { expires: 1 });
      redirectToLocale(regionCountries[browserLanguageMapping[userCountry]].storefront);
    }
  };

  const onAdditionalLangSubmit = (storefront: string) => {
    setShowPopup(false);
    redirectToLocale(storefront);
  };

  const getNameFromLocale = (name: string): string => {
    return regionCountries[name]?.name ?? '';
  };

  useEffect(() => {
    // clear existing state (with dep of locale)
    setShowAdditionalLanguages(false);
    setShowPopup(false);
  }, [locale]);

  useEffect(() => {
    if (browserLanguageMapping[userCountry] && regionCountries[browserLanguageMapping[userCountry]]) {
      if (locale.toLowerCase() !== userCountry && locale.toLowerCase() !== browserLanguageMapping[userCountry]) {
        setShowPopup(true);
      }
    } else {
      trackMissedBrowserLocale(userCountry);
    }
  }, [userCountry, locale]);

  if (!showPopup || hasBeenDismissed === 'yes') {
    return null;
  }

  return (
    <div className={s.root}>
      <div className={cn(s.header, showAdditionalLanguages && 'justify-end')}>
        <div className={s.body}>
          {!showAdditionalLanguages && (
            <Text variant="text-2">
              {t('common:languageSwitcher.description', {
                country: t(`countries:countries.${getNameFromLocale(browserLanguageMapping[userCountry])}`),
              })}
            </Text>
          )}
        </div>
        <div className={s.close}>
          <button onClick={onClose} aria-label={t('common:close')} type="button">
            <FontAwesomeIcon icon={faCircleXmark} color="var(--purple)" />
          </button>
        </div>
      </div>
      {showAdditionalLanguages ? (
        <div className={s.actions}>
          {regionCountries[browserLanguageMapping[userCountry]].additionalLanguages.map((additionalLang) => (
            <Button
              variant="cta"
              type="button"
              onClick={(e) => onAdditionalLangSubmit((e.target as HTMLInputElement)?.value)}
              value={additionalLang.storefront}
              key={additionalLang.language}
            >
              {additionalLang.language}
            </Button>
          ))}
        </div>
      ) : (
        <div className={s.actions}>
          <Button type="button" onClick={onClose}>
            {t(`countries:countries.${isLife360IntlSite() ? 'INTL' : getNameFromLocale(locale)}`)}
          </Button>
          <Button variant="cta" type="button" onClick={onSubmit}>
            {t(`countries:countries.${getNameFromLocale(browserLanguageMapping[userCountry])}`)}
          </Button>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
