import cn from 'classnames';
import { FC } from 'react';

import Link from '@components/ui/Link';

import { ClickableProps, NavigationSubmenu } from '../types';

import s from './Submenu.module.scss';

export const Submenu: FC<ClickableProps<NavigationSubmenu>> = ({ title, links, onClick }) => (
  <nav className="flex flex-col">
    <p className="text-left uppercase font-bold text-purple text-base mb-[10px] leading-4 cursor-default">{title}</p>
    <ul className="space-y-0.5">
      {links.map((l, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`${i}${l.id}`} className="text-left">
          <Link
            href={l.content.url}
            className={cn(s.link, 'text-black text-base font-normal leading-[22.4px] relative')}
            prefetch={false}
            onClick={onClick}
          >
            {l.content.title}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);
