import { Experience as ExperienceComponent } from '@ninetailed/experience.js-next';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { FC, forwardRef, RefObject, useImperativeHandle, useMemo, useRef } from 'react';

import { mapExperiences } from '@components/screen/mapExperience';
import { NtConfigurable } from '@components/screen/types';
import Text from '@components/ui/Text/Text';
import extractColorHex from '@lib/extract-color-hex';

import { MessageBarHeightHandle, ModuleMessageBar } from '../types/ModuleMessageBar';

import styles from './MessageBar.module.scss';

interface Props {
  content?: string;
  backgroundColor?: string;
  textColor?: string;
  underlineHyperlinks?: boolean;
}

const MessageBar = forwardRef<MessageBarHeightHandle, Props>(
  ({ content, backgroundColor, textColor, underlineHyperlinks }, ref) => {
    const textRef = useRef<HTMLDivElement>(null);

    const bgColor = (backgroundColor && extractColorHex(backgroundColor)) ?? 'var(--mint)';
    const color = (textColor && extractColorHex(textColor)) ?? 'var(--eggplant)';

    useImperativeHandle(ref, () => {
      return {
        heightMeasure() {
          return textRef.current?.offsetHeight;
        },
      };
    }, []);

    return (
      <div className="message-bar" style={{ backgroundColor: bgColor }}>
        {content && (
          <Text
            className={cn(styles.root, underlineHyperlinks && styles.underlined)}
            color={color}
            variant="medium"
            asElement="div"
            data-testid="message-bar-wrapper"
            html={content}
            ref={textRef}
          />
        )}
      </div>
    );
  }
);

MessageBar.displayName = 'MessageBar';

export default MessageBar;

const MessageBarPicker: FC<ModuleMessageBar & { ref: RefObject<MessageBarHeightHandle> }> = ({
  ref,
  messageBarContent,
  messageBarBackgroundColor,
  messageBarTextColor,
  underlineHyperlinks,
}) => (
  <MessageBar
    content={messageBarContent}
    backgroundColor={messageBarBackgroundColor}
    textColor={messageBarTextColor}
    underlineHyperlinks={underlineHyperlinks}
    ref={ref}
  />
);

export const MessageBarWrapper: FC<NtConfigurable<ModuleMessageBar> & { ref: RefObject<MessageBarHeightHandle> }> = ({
  nt_experiences: experiences,
  ref,
  ...props
}) => {
  const { id, ...contentProps } = props;
  const mappedExperiences = useMemo(() => (experiences ? mapExperiences(experiences) : []), [experiences]);
  if (isEmpty(mappedExperiences)) {
    return <MessageBarPicker ref={ref} {...props} />;
  }

  return (
    <ExperienceComponent
      id={id}
      experiences={mappedExperiences}
      component={MessageBarPicker}
      {...contentProps}
      passthroughProps={{ ref }}
      loadingComponent={() => null}
    />
  );
};
