import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import isString from 'lodash/isString';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { validateEmailFormatOnly } from '@components/checkout/validations';
import Button from '@components/ui/Button/Button';
import { useUI } from '@components/ui/context';
import InputText from '@components/ui/InputText/InputText';
import { ModalView } from '@components/ui/Modal/Modal';
import { ModalViews } from '@components/ui/modals';
import PasswordInputText from '@components/ui/PasswordInputText/PasswordInputText';
import Text from '@components/ui/Text/Text';
import { ErrorCode } from '@framework/api/utils/error-code';
import useLogin from '@framework/auth/use-login';
import useCustomer from '@framework/customer/use-customer';
import getErrorCode from '@lib/get-error-code';
import { getI18nError } from '@lib/get-i18n-error';

import style from '../auth.module.scss';

type LoginFormValues = {
  email: string;
  password: string;
};

interface Props extends ModalView {
  email?: string;
  isPageCheckout?: boolean;
  showAccountVerified?: boolean;
  redirectToPath?: string;
}

const AccountVerifiedLabel = () => {
  const { t } = useTranslation(['auth']);

  return (
    <div className={style.verifiedWrapper}>
      <div className={style.verifiedLabel}>
        <Text variant="text-4" weight="bold" color="var(--white)" className={style.verifiedText}>
          {t('label.verified')}
        </Text>
        <FontAwesomeIcon className={cn(style.verifiedText, 'ml-1.5')} icon={faCheck} title={t('label.verified')} />
      </div>
    </div>
  );
};

const PasswordLoginView: FC<Props> = ({
  email: defaultEmail,
  closeModal,
  isPageCheckout = false,
  showAccountVerified = false,
  redirectToPath,
}) => {
  const { t } = useTranslation(['common', 'auth']);

  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const { setModal } = useUI();
  const router = useRouter();
  const { data: customer } = useCustomer();

  const defaultValues: LoginFormValues = { email: defaultEmail ?? '', password: '' };

  const {
    register,
    handleSubmit,
    setError,
    trigger,
    formState: { errors, isValid, isSubmitted },
    getValues,
  } = useForm<LoginFormValues>({
    defaultValues,
    mode: 'onTouched',
  });

  const login = useLogin();

  const validationRules = {
    email: {
      required: t('common:error.empty.email'),
      validate: {
        value: (email: string) => validateEmailFormatOnly(email).success || t('auth:error.invalid_email').toString(),
      },
    },
    password: {
      required: t('common:error.empty.password'),
      // TBD whether to also client-side validate password field for login
      // minLength: { value: PASSWORD_LENGTH, message: t('auth:error.password_too_short') },
      // pattern: { value: new RegExp(PASSWORD_PATTERN), message: t('auth:error.invalid_password') },
    },
  };

  const onSubmit = async ({ email, password }: LoginFormValues) => {
    trigger();
    if (!isSubmitted || isValid) {
      try {
        setLoading(true);
        await login({
          email,
          password,
        });

        setUserEmail(email);
        setLoading(false);
      } catch (err) {
        const errorCode = getErrorCode(err);
        if (errorCode === ErrorCode.FORBIDDEN) {
          setError('email', { message: getI18nError(t, errorCode, 'auth:error.') }, { shouldFocus: true });
        } else {
          setError('password', { message: getI18nError(t, errorCode, 'auth:error.') }, { shouldFocus: true });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleForgotPassword = () => {
    if (getValues('email')) {
      router.push({ pathname: '/reset-password', query: { email: getValues('email') } });
    } else {
      router.push({ pathname: '/reset-password' });
    }
  };

  useEffect(() => {
    if (!userEmail) {
      return;
    }

    if (!customer) {
      setModal('RESEND_VIEW', { email: userEmail, countDown: 0 });
      return;
    }

    if (!isPageCheckout) {
      closeModal?.();
      const pathname = redirectToPath && isString(redirectToPath) ? redirectToPath : '';
      // also ensure if `next`redirect query is a valid path before redirecting
      // else stay on current page
      if (pathname.length && /^(?:\/[\w-]*)+$/i.test(pathname) && pathname !== '/') {
        router.push({ pathname, query: router.query });
      }
    }
  }, [customer, userEmail, isPageCheckout, closeModal, setModal, redirectToPath, router]);

  const handleModalView = (e: MouseEvent | KeyboardEvent, view: ModalViews) => {
    if (e.type === 'click' || (e.type === 'keydown' && (e as KeyboardEvent).key === 'Enter')) {
      setModal(view, !errors.email ? { email: getValues('email') } : undefined);
    }
  };

  useEffect(() => {
    // this modal view can be opened by setting the modal value in the query parameter
    // we should remove the parameters when opened
    if (router.isReady && (router.query.modal || router.query.email)) {
      const { modal: _modal, email: _email, ...query } = router.query;
      router.replace(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router]);

  return (
    // noValidate to disable default browser form validation
    <form onSubmit={handleSubmit(onSubmit)} className={style.form} noValidate>
      {showAccountVerified && <AccountVerifiedLabel />}
      <Text className="mb-xxl text-center" variant="heading-3" weight="semibold">
        {t('auth:header.login')}
      </Text>
      <InputText
        type="email"
        className="mb-xxl"
        label={t('auth:label.email')}
        data-cy="login-email"
        error={errors.email}
        defaultValue={defaultValues.email}
        aria-required={!!validationRules.email.required}
        {...register('email', validationRules.email)}
      />
      <PasswordInputText
        className="mb-xxl"
        label={t('auth:label.password')}
        required
        data-cy="login-password"
        error={errors.password}
        aria-required={!!validationRules.password.required}
        registerInput={register('password', validationRules.password)}
      />
      <Button
        variant="link"
        type="button"
        className={cn(style.linkWrapped, 'mb-xxl')}
        data-cy="forgot-password"
        onClick={handleForgotPassword}
      >
        <Text className={style.linkText} variant="text-4" color="var(--cta)">
          {t('auth:action.forgotPassword')}
        </Text>
      </Button>
      <Button
        variant="cta"
        type="submit"
        className={cn(style.button, 'mb-l w-full')}
        loading={loading}
        disabled={isSubmitted && !isValid}
        data-cy="login-password-button"
      >
        <Text variant="heading-5" asElement="span">
          {t('auth:action.continue')}
        </Text>
      </Button>
      <Button
        type="button"
        className={cn(style.button, 'mb-xxl')}
        data-cy="login-with-link"
        disabled={loading}
        onKeyDown={(e) => handleModalView(e, 'LINK_LOGIN_VIEW')}
        onClick={(e) => handleModalView(e, 'LINK_LOGIN_VIEW')}
      >
        <Text variant="heading-5" asElement="span">
          {t('auth:action.useLink')}
        </Text>
      </Button>
      <a
        className={style.link}
        role="button"
        data-cy="signup-link"
        tabIndex={0}
        onKeyDown={(e) => handleModalView(e, 'SIGNUP_VIEW')}
        onClick={(e) => handleModalView(e, 'SIGNUP_VIEW')}
      >
        <Text className={style.linkText} variant="text-4" color="var(--cta)" asElement="span">
          {t('auth:action.signUpLink')}
        </Text>
      </a>
    </form>
  );
};

export default PasswordLoginView;
