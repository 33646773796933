import { SUPPORTED_LOCALES } from '@lib/locales';
import { isLife360IntlSite } from '@lib/storefront';

type Language = (typeof LANGUAGE_MAP)[number];

export type LocaleMapData = { lang: Language[]; img: string; url?: string[] };

export type LocaleMap = Record<string, LocaleMapData>;

interface LocaleData {
  name: string;
  shortName: string;
  flag: string;
}

export const LANGUAGE_MAP = ['da', 'de', 'en', 'es', 'fi', 'fr', 'it', 'jp', 'nl', 'no', 'pl', 'pt', 'sv'] as const;

export const LOCALES_MAP: Record<string, LocaleData> = {
  [SUPPORTED_LOCALES.EN_US]: isLife360IntlSite()
    ? {
        name: 'INTL',
        shortName: 'INTL',
        flag: '🌎',
      }
    : {
        name: 'United States',
        shortName: 'US',
        flag: '🇺🇸',
      },
  [SUPPORTED_LOCALES.EN_CA]: {
    name: 'Canada (English)',
    shortName: 'CA (EN)',
    flag: '🇨🇦',
  },
  [SUPPORTED_LOCALES.FR_CA]: {
    name: 'Canada (Français)',
    shortName: 'CA (FR)',
    flag: '🇨🇦',
  },
  [SUPPORTED_LOCALES.EN_AU]: {
    name: 'Australia',
    shortName: 'AU',
    flag: '🇦🇺',
  },
  [SUPPORTED_LOCALES.EN_NZ]: {
    name: 'New Zealand',
    shortName: 'NZ',
    flag: '🇳🇿',
  },
  [SUPPORTED_LOCALES.EN_GB]: {
    name: 'British English',
    shortName: 'GB',
    flag: '🇬🇧',
  },
  // [SUPPORTED_LOCALES.DE_EU]: {
  //   name: 'Europe (German)',
  //   shortName: 'DE',
  // },
  [SUPPORTED_LOCALES.EN_EU]: {
    name: 'Europe (English)',
    shortName: 'EU',
    flag: '🇪🇺',
  },
  // [SUPPORTED_LOCALES.ES_EU]: {
  //   name: 'Europe (Spanish)',
  //   shortName: 'ES',
  //   flag: '🇪🇺',
  // },
  // [SUPPORTED_LOCALES.FR_EU]: {
  //   name: 'Europe (French)',
  //   shortName: 'FR',
  //   flag: '🇪🇺',
  // },
  // [SUPPORTED_LOCALES.IT_EU]: {
  //   name: 'Europe (Italian)',
  //   shortName: 'IT',
  //   flag: '🇪🇺',
  // },
  // [SUPPORTED_LOCALES.NL_EU]: {
  //   name: 'Europe (Dutch)',
  //   shortName: 'NL',
  //   flag: '🇪🇺',
  // },
  [SUPPORTED_LOCALES.EN_IE]: {
    name: 'Ireland (English)',
    shortName: 'IE (EN)',
    flag: '🇮🇪',
  },
  [SUPPORTED_LOCALES.EN_FR]: {
    name: 'France (English)',
    shortName: 'FR (EN)',
    flag: '🇫🇷',
  },
  [SUPPORTED_LOCALES.EN_DE]: {
    name: 'Germany (English)',
    shortName: 'DE (EN)',
    flag: '🇩🇪',
  },
  [SUPPORTED_LOCALES.EN_AT]: {
    name: 'Austria (English)',
    shortName: 'AT (EN)',
    flag: '🇦🇹',
  },
  [SUPPORTED_LOCALES.EN_BE]: {
    name: 'Belgium (English)',
    shortName: 'BE (EN)',
    flag: '🇧🇪',
  },
  [SUPPORTED_LOCALES.EN_FI]: {
    name: 'Finland (English)',
    shortName: 'FI (EN)',
    flag: '🇫🇮',
  },
  [SUPPORTED_LOCALES.EN_CH]: {
    name: 'Switzerland (English)',
    shortName: 'CH (EN)',
    flag: '🇨🇭',
  },
  [SUPPORTED_LOCALES.EN_NL]: {
    name: 'Netherlands (English)',
    shortName: 'NL (EN)',
    flag: '🇳🇱',
  },
  [SUPPORTED_LOCALES.NL_NL]: {
    name: 'Netherlands (Dutch)',
    shortName: 'NL',
    flag: '🇳🇱',
  },
  // [SUPPORTED_LOCALES.SV_SE]: {
  //   name: 'Sweden (Swedish)',
  //   shortName: 'SE',
  //   flag: '🇸🇪',
  // },
  // [SUPPORTED_LOCALES.FI_FI]: {
  //   name: 'Finland (Finnish)',
  //   shortName: 'FI',
  //   flag: '🇫🇮',
  // },
  // [SUPPORTED_LOCALES.NO_NO]: {
  //   name: 'Norway (Norwegian)',
  //   shortName: 'NO',
  //   flag: '🇳🇴',
  // },
  // [SUPPORTED_LOCALES.DA_DK]: {
  //   name: 'Denmark (Danish)',
  //   shortName: 'DK',
  //   flag: '🇩🇰',
  // },
  // [SUPPORTED_LOCALES.DE_DE]: {
  //   name: 'Germany (German)',
  //   shortName: 'DE',
  //   flag: '🇩🇪',
  // },
};

export const MAIN_LOCALES_MAP: LocaleMap = {
  US: { lang: ['en'], img: '🇺🇸' },
  UK: { lang: ['en'], img: '🇬🇧' },
  JP: { lang: ['jp'], img: '🇯🇵', url: ['https://thetileapp.jp'] },
  AU: { lang: ['en'], img: '🇦🇺' },
};

export const SECONDARY_LOCALES_MAP: LocaleMap = {
  AT: { lang: ['de', 'en'], img: '🇦🇹' },
  BE: { lang: ['de', 'fr', 'nl', 'en'], img: '🇧🇪', url: ['https://be.tile.com/nl'] },
  CA: { lang: ['en'], img: '🇨🇦' },
  CH: { lang: ['de', 'fr', 'it', 'en'], img: '🇨🇭' },
  DE: { lang: ['de', 'en'], img: '🇩🇪' },
  DK: { lang: ['da', 'en'], img: '🇩🇰', url: ['https://dk.tile.com/dk'] },
  ES: { lang: ['es', 'en'], img: '🇪🇸' },
  EU: { lang: ['en'], img: '🇪🇺', url: ['https://ie.tile.com'] },
  FI: { lang: ['fi', 'en'], img: '🇫🇮' },
  FR: { lang: ['fr', 'en'], img: '🇫🇷' },
  IE: { lang: ['en'], img: '🇮🇪' },
  IT: { lang: ['it', 'en'], img: '🇮🇹' },
  NZ: { lang: ['en'], img: '🇳🇿' },
  PL: { lang: ['pl', 'en'], img: '🇵🇱' },
  PT: { lang: ['pt', 'en'], img: '🇵🇹' },
  SE: { lang: ['sv', 'en'], img: '🇸🇪', url: ['https://se.tile.com/se'] },
  NL: { lang: ['nl', 'en'], img: '🇳🇱' },
  NO: { lang: ['no', 'en'], img: '🇳🇴' },
};

export const l360IntlBaseUrl = process.env.NEXT_PUBLIC_LIFE360_INTL_BASE_URL || '';
const l360BaseUrl = process.env.NEXT_PUBLIC_LIFE360_BASE_URL?.replace(/\/$/gi, '') || '';

export const L360_MAIN_LOCALES_MAP: LocaleMap = {
  US: { lang: ['en'], img: '🇺🇸', url: [`${l360BaseUrl}/en-us`] },
  CA: { lang: ['en'], img: '🇨🇦', url: [`${l360BaseUrl}/en-ca`] },
  AU: { lang: ['en'], img: '🇦🇺', url: [`${l360BaseUrl}/en-au`] },
  UK: { lang: ['en'], img: '🇬🇧', url: [`${l360BaseUrl}/en-gb`] },
};

export const L360_SECONDARY_LOCALES_MAP: LocaleMap = {
  INTL: {
    lang: ['en'],
    img: '🌎',
    url: [l360IntlBaseUrl],
  },
  NL: { lang: ['nl'], img: '🇳🇱', url: [`${l360BaseUrl}/nl-nl`] },
  NZ: { lang: ['en'], img: '🇳🇿', url: [`${l360BaseUrl}/en-nz`] },
  // SE: { lang: ['sv'], img: '🇸🇪', url: [`${l360BaseUrl}/sv-se`] },
  // FI: { lang: ['fi'], img: '🇫🇮', url: [`${l360BaseUrl}/fi-fi`] },
  // NO: { lang: ['no'], img: '🇳🇴', url: [`${l360BaseUrl}/no-no`] },
  // DK: { lang: ['da'], img: '🇩🇰', url: [`${l360BaseUrl}/da-dk`] },
  // DE: { lang: ['de'], img: '🇩🇪', url: [`${l360BaseUrl}/de-de`] },
};
